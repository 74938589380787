import React from 'react';
import styled from 'styled-components';
import OrangeButton from '../buttons/orangebutton/OrangeButton';
import { playBingoCtx } from '../../store/bingolobbycontext';

const InfoModalBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ModalDiv = styled.div`
    position: relative;
    width: 51.11vh;
    height: 47.22vh;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vh;
    border-radius: 2vh;
    margin-top: 5vh;
`;

const ModalIcon = styled.img`
    width: 10vh;
    height: 10vh;
    image-rendering: -webkit-optimize-contrast;
`;

const TitleHeader = styled.h1`
    font-size: 2.67vh;
    color: #3d3d3e;
    margin: 2vh 0 0 0;
    text-align: center;
    line-height: normal;
`;

const FirstText = styled.h1`
    font-size: 1.77vh;
    color: #3d3d3e;
    margin: 2vh 0 0 0;
    text-align: center;
    font-weight: 400;
    line-height: normal;
`;

const SecondText = styled(FirstText)`
    margin: 2vh 0 3vh 0;
`;

const InfoModal = (props) => {
    const { type, title, message, messagetwo, onClickFunc } = props;

    const { showInfoModal } = playBingoCtx();

    const onOkClick = () => {
        showInfoModal(null);
        onClickFunc();
    };

    return (
        <InfoModalBackground>
            <ModalDiv>
                <ModalIcon
                    src={
                        type === 'check'
                            ? '/assets/icons/checkicon.png'
                            : type === 'lock'
                            ? '/assets/icons/lockicon.png'
                            : '/assets/icons/checkicon.png'
                    }
                />
                <TitleHeader>{title}</TitleHeader>
                <FirstText>{message}</FirstText>
                <SecondText>{messagetwo}</SecondText>
                <OrangeButton
                    onClick={onOkClick}
                    width="17.2vh"
                    fontsize="2.66vh"
                    padding="1vh 0vh 1vh 0vh"
                >
                    Ok
                </OrangeButton>
            </ModalDiv>
        </InfoModalBackground>
    );
};

export default InfoModal;
