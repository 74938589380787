import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import '../styles/globals.css';
import { BingoLobbyContextProvider } from '../store/bingolobbycontext';
import Layout from '../components/layout/Layout';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    background: transparent;
  }
`;

function BingoIframe({ Component, pageProps }) {
    return (
        <BingoLobbyContextProvider>
            <GlobalStyle />
            <Layout>
                <Component {...pageProps} />
            </Layout>
        </BingoLobbyContextProvider>
    );
}

export default BingoIframe;
