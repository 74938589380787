import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

const BingoLobbyContext = createContext({
  lobbyModalVisible: false,
  launchData: {},
  setLobbyModalVisible() {},
  setLaunchDataItems() {},
  notification: null,
  showNotification() {},
});

export const BingoLobbyContextProvider = (props) => {
  const { children } = props;
  const [modalVisible, setModalVisible] = useState();
  const [activeNotification, setActiveNotification] = useState();
  const [launchData, setLaunchData] = useState({});

  useEffect(() => {
    if (activeNotification) {
      const timer = setTimeout(() => {
        setActiveNotification(null);
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeNotification]);

  const showLobbyModal = useCallback(
    (lobbyModalData) => {
      setModalVisible(lobbyModalData);
    },
    [setModalVisible]
  );

  const launchDataConfigure = useCallback(
    (lobbyModalData) => {
      setLaunchData({ ...lobbyModalData });
    },
    [setLaunchData]
  );
  const showNotificationHandler = useCallback(
    (notificationData) => {
      setActiveNotification(notificationData);
    },
    [setActiveNotification]
  );

  const context = {
    notification: activeNotification,
    showNotification: showNotificationHandler,
    lobbyModalVisible: modalVisible,
    setLobbyModalVisible: showLobbyModal,
    launchData,
    setLaunchDataItems: launchDataConfigure,
  };

  return (
    <BingoLobbyContext.Provider value={context}>
      {children}
    </BingoLobbyContext.Provider>
  );
};

/* eslint-disable */
export const bingoLobbyCtx = () => useContext(BingoLobbyContext);

export default BingoLobbyContext;
