import dynamic from 'next/dynamic';
import React from 'react';
import InfoModal from '../infomodal/InfoModal';

import { bingoLobbyCtx } from '../../store/bingolobbycontext';

const MobileNotification = dynamic(() =>
    import('../notification/MobileNotification')
);
const WebNotification = dynamic(() =>
    import('../notification/WebNotification')
);

const Layout = ({ children }) => {
    const { notification, infoModal } = bingoLobbyCtx();

    return (
        <>
            {infoModal && (
                <InfoModal
                    type={infoModal.type}
                    title={infoModal.title}
                    message={infoModal.message}
                    messagetwo={infoModal.messagetwo}
                    onClickFunc={infoModal.onClickFunc}
                />
            )}
            {notification && (
                <>
                    <MobileNotification
                        type={notification.type}
                        message={notification.message}
                        messagetwo={notification.messagetwo}
                    />
                    <WebNotification
                        type={notification.type}
                        message={notification.message}
                        messagetwo={notification.messagetwo}
                    />
                </>
            )}
            <main>{children}</main>
        </>
    );
};

export default Layout;
