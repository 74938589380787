import styled from 'styled-components';

const OrangeButton = styled.button`
    margin-top: ${(props) => props.margintop};
    margin-bottom: ${(props) => props.marginbottom};
    margin-left: ${(props) => props.marginleft};
    margin-right: ${(props) => props.marginright};
    width: ${(props) => (props.width ? props.width : '60%')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    background-color: ${(props) =>
        props.background ? props.background : '#ff9100'};
    font-size: ${(props) => (props.fontsize ? props.fontsize : '1.12rem')};
    padding: ${(props) =>
        props.padding ? props.padding : '8px 3.5px 8px 3.5px'};
    font-weight: 500;
    border: 1px solid transparent;
    color: white;
    border-radius: 57.7px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: ${(props) => (props.mobileWidth ? props.mobileWidth : '31.2vw')};
        height: ${(props) =>
            props.mobileHeight ? props.mobileHeight : '10.4vw'};
        font-size: ${(props) =>
            props.mobileFontsize ? props.mobileFontsize : '4.5vw'};
    }

    &:hover {
        cursor: pointer;
        background: none;
        color: ${(props) => (props.background ? props.background : '#ff9100')};
        border: ${(props) =>
            props.background
                ? `1px solid ${props.background}`
                : '1px solid #ff9100'};
    }
`;

export default OrangeButton;
